<template>
  <article class="drawed-border p-6 lg:px-16 lg:py-10 bg-secondary relative">
    <Modal v-model="modal.isOpen" :heading="modal.heading" :body="modal.body" :footer="modal.footer" />
    <p
      class="mb-4 lg:mb-0 lg:absolute lg:top-0 lg:right-7 font-serif font-bold text-[80px] text-center lg:text-left lg:text-[100px] leading-none text-secondary-dark"
    >
      {{ calendar.liturgicalYear }}
    </p>
    <div class="text-primary font-serif font-bold mb-2 lg:mb-0">
      <time :datetime="calendar.date" class="text-2xl">{{ calendar.formattedDate + ', ' }}</time>
      <span class="text-base">{{ calendar.weekDay }}</span>
    </div>
    <div>
      <span class="border-2 w-2 mr-2 lg:mr-3" :class="matchColor()"></span>
      <span class="font-semibold text-sm lg:text-lg text-grey">{{ calendar.celebration }}</span>
      <span v-if="calendar.solemnity" class="text-sm lg:text-lg text-grey/30 font-semibold"> ({{ calendar.solemnity }})</span>
    </div>
    <div class="grid custom-grid lg:auto-cols-auto grid-flow-row lg:grid-flow-col lg:gap-x-8 mt-6 space-y-1 lg:space-y-0">
      <p class="inline font-bold text-sm lg:text-base text-primary">
        {{ calendar.reading[0].badge }}:
        <span class="litcal__coords" @click="openModal(calendar.reading[0])">{{ calendar.reading[0].coords }}</span>
      </p>
      <p v-if="calendar.reading?.[1]?.coords" class="inline font-bold text-sm lg:text-base text-primary">
        {{ calendar.reading[1].badge }}:
        <span class="litcal__coords" @click="openModal(calendar.reading[1])">{{ calendar.reading[1].coords }}</span>
      </p>
      <p v-else class="inline font-bold text-sm lg:text-base text-primary"></p>
      <p v-if="calendar.psalm?.coords" class="inline font-bold text-sm lg:text-base text-primary">
        {{ calendar.psalm.badge }}:
        <span class="litcal__coords" @click="openModal(calendar.psalm)">{{ calendar.psalm.chorus }} ({{ calendar.psalm.coords }})</span>
      </p>
      <p class="inline font-bold text-sm lg:text-base text-primary">
        {{ calendar.gospel.badge }}: <span class="litcal__coords" @click="openModal(calendar.gospel)">{{ calendar.gospel.coords }}</span>
      </p>
    </div>
  </article>
</template>
<script setup lang="ts">
const props = defineProps({
  calendar: {
    type: Object,
    required: true,
  },
})

const modal = reactive({
  isOpen: false,
  body: '',
  heading: '',
  footer: [],
})

const matchColor = () => {
  const color = props.calendar.liturgicalColor ?? ''
  switch (color) {
    case 'Červená':
      return 'border-red-500'
    case 'Fialová':
      return 'border-violet-500'
    case 'Biela':
      return 'border-white'
    case 'Zelená':
      return 'border-emerald-600'
    case 'Ružová':
      return 'border-pink-500'
    case 'Čierna':
      return 'border-black'
    default:
      return 'border-grey'
  }
}

const openModal = (reading: any) => {
  modal.body = reading.text
  modal.heading = `${reading.badge}: ${reading.coords}`
  modal.isOpen = true
}
</script>

<style scoped>
@media screen(lg) {
  .custom-grid {
    grid-template-rows: repeat(2, minmax(0, max-content));
  }
}

.litcal__coords {
  @apply cursor-pointer font-normal text-grey-light border-b border-grey-light;
}
</style>
