<template>
  <ClientOnly>
    <Teleport to="body">
      <div class="modal-wrapper" v-if="modelValue" @click.self="closeModal">
        <div class="modal animate__animated animate__fadeInUp">
          <div class="close-btn text-right">
            <UtilityButton is-button class="bg-secondary !p-4" @click="closeModal" @mouseenter="animation($event, 'heartBeat')">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                class="w-6 h-6 text-primary"
              >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
              <span class="sr-only">Close modal button</span>
            </UtilityButton>
          </div>
          <div class="modal-content space-y-5">
            <Heading :text="heading" :size="3" />
            <p v-html="body" class="leading-loose text-grey-light"></p>
            <div v-if="footer.length" class="flex gap-3">
              <UtilityButton
                v-for="(item, index) in footer"
                :key="index"
                :link="item.link"
                :is-primary="item.isPrimary"
                @mouseenter="animation($event, 'heartBeat')"
              >
                {{ item.text }}
              </UtilityButton>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>

<script setup>
const animation = useAnimation()
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },

  heading: {
    type: String,
    default: '',
  },

  body: {
    type: String,
    default: '',
  },

  footer: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmits(['update:model-value'])

const closeModal = () => {
  emit('update:model-value', false)
}

const handleKeydown = (event) => {
  if (event.key === 'Escape') {
    closeModal()
  }
}

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      document.addEventListener('keydown', handleKeydown)
    } else {
      document.removeEventListener('keydown', handleKeydown)
    }
  },
)
</script>

<style scoped lang="scss">
.modal-wrapper {
  @apply fixed grid inset-0 z-50 bg-black bg-opacity-50 overflow-auto;
}

.modal {
  @apply flex flex-col justify-self-center self-center max-w-[858px] h-full md:h-auto bg-white rounded-[10px] p-7;
  --animate-duration: 0.4s;

  .close-btn {
    --animate-duration: 1s;
  }

  .modal-content {
    @apply px-4 pb-7 lg:px-24 lg:pb-14;
  }
}
</style>
